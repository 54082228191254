import Layout from '../../components/layout';
import IntroLayout from '../../components/sections/showcase/introLayout';
import Views from '../../components/sections/showcase/vestur/views';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import content from '../../content/showcase/vestur/intro.json';
// @ts-ignore
import video from '../../videos/vestur.mp4';

const Vestur = () => (
	<Layout>
		<SEO title={titles.showcase.vestur} description={descriptions.showcase.vestur} />

		<IntroLayout content={content} devices={{ desktop: true, mobile: true }} scaleMobile video={video} />
		<Views />
	</Layout>
);

export default Vestur;
