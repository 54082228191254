import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, smallText } from '../../../../styles/dimensions';
import content from '../../../../content/showcase/vestur/views.json';
import MetroSans from '../../../../fonts/metro-sans/metroSans';

const Views = () => {
	const isSmall = useMediaQuery(useTheme().breakpoints.down(1232));

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<div className={classes.container} data-scroll data-scroll-speed="2">
				<Grid container spacing={isSmall ? 2 : 6} classes={{ root: classes.imageGrid }}>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/vestur/open-report.png"
							alt="Open report preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/vestur/fully-opened.png"
							alt="Fully opened report preview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.image}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StaticImage
							src="../../../../images/showcase/vestur/menu_open.png"
							alt="Menu opened on report"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
						/>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item md={1}></Grid>

					<Grid item xs={12} md={10}>
						<div className={classes.goalContainer}>
							<div className={classes.goalWrapper}>
								<h5 className={classes.goalTitle}>{content.goal.title}</h5>
								<p className={classes.goalDescription}>{content.goal.col1}</p>
							</div>
							<div className={classes.goalWrapper}>
								<p className={`${classes.goalDescription} ${classes.goalCol2}`}>{content.goal.col2}</p>
							</div>
						</div>
					</Grid>

					<Grid item md={1}></Grid>
				</Grid>
				<StaticImage
					src="../../../../images/showcase/vestur/dashboard.png"
					alt="Vestur Management Interface dashboard"
					layout="constrained"
					loading="lazy"
					placeholder="blurred"
					objectFit="contain"
				/>
			</div>

			<StaticImage
				src="../../../../images/showcase/vestur/showcase_vestur.jpg"
				alt="Vestur showcase"
				layout="fullWidth"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="50% 50%"
				className={classes.showcaseImage}
			/>
		</section>
	);
};

export default Views;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.backgroundDarkGray,
		'@media (max-width: 599px)': {
			paddingTop: '4em',
		},
	},
	container: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media(max-width: 1231px)': {
			padding: '0 1em',
		},
	},
	imageGrid: {
		marginBottom: '6.75em',
		'@media (max-width: 599px)': {
			marginBottom: '4em',
		},
	},
	image: {
		'@media (max-width: 599px)': {
			marginBottom: '2em',
		},
	},
	goalContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '7.125em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			marginBottom: '4em',
		},
	},
	goalWrapper: {
		'@media (min-width: 600px)': {
			width: 'calc(50% - 1em)',
		},
	},
	goalTitle: {
		letterSpacing: '0.075em',
		color: colors.white,
		fontFamily: Rigraf.regular,
		marginTop: 0,
		marginBottom: '1.5em',
	},
	goalDescription: {
		...smallText,
		color: colors.careersJobGray,
		fontFamily: MetroSans.book,
	},
	goalCol2: {
		'@media (min-width: 600px)': {
			marginTop: '3.875em',
		},
	},
	showcaseImage: {
		maxHeight: '100vh',
		marginTop: '2.25em',
		'@media (max-width: 599px)': {
			marginTop: '4em',
		},
	},
});
